var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dapp-detailed-tokens-nfts-section"},[_c('div',{staticClass:"dapp-detailed-tokens-nfts-section__header",class:{
      'dapp-detailed-tokens-nfts-section__header--only-one':
        (_vm.existsNfts && !_vm.existsTokens) || (_vm.existsTokens && !_vm.existsNfts),
    }},[(_vm.existsTokens)?_c('button',{staticClass:"dapp-detailed-tokens-nfts-section__nav-section",class:{
        'dapp-detailed-tokens-nfts-section__nav-section--only-one': !_vm.existsNfts,
        'dapp-detailed-tokens-nfts-section__nav-section--disabled': !_vm.shouldDisplayTokensTable,
      },on:{"click":function($event){_vm.shouldDisplayTokensTable = true}}},[_c('img',{staticClass:"w-8 h-8 object-contain",attrs:{"alt":"Tokens icon","src":require("@/assets/img/my-tokens-icon.svg")}}),_c('span',{staticClass:"font-bold text-xl md:text-2xl"},[_vm._v(" "+_vm._s(_vm.$translate('components.DappDetailedTokensNftsSection.tokens'))+" ")])]):_vm._e(),(_vm.existsNfts)?_c('button',{staticClass:"dapp-detailed-tokens-nfts-section__nav-section",class:{
        'dapp-detailed-tokens-nfts-section__nav-section--only-one': !_vm.existsTokens,
        'dapp-detailed-tokens-nfts-section__nav-section--disabled': _vm.shouldDisplayTokensTable,
      },on:{"click":function($event){_vm.shouldDisplayTokensTable = false}}},[_c('img',{staticClass:"w-8 h-8 object-contain",attrs:{"alt":"NFTs icon","src":require("@/assets/img/my-nfts-icon.svg")}}),_c('span',{staticClass:"font-bold text-xl md:text-2xl"},[_vm._v(" "+_vm._s(_vm.$translate('components.DappDetailedTokensNftsSection.nfts'))+" ")])]):_vm._e()]),(_vm.existsTokens && _vm.shouldDisplayTokensTable)?_c('div',{staticClass:"dapp-detailed-tokens-nfts-section__table-wrapper",class:{
      'dapp-detailed-tokens-nfts-section__table-wrapper--only-one': !_vm.existsNfts,
    }},[_c('div',{staticClass:"justify-between horiz"},[_c('last-price-updated',{staticClass:"dapp-detailed-tokens-nfts-section__last-time-updated",attrs:{"display-inline":_vm.isMobile || _vm.isTablet,"last-time-updated":_vm.tokensTableLastTimeUpdated,"icon-class":"w-4 h-4 md:w-7 md:h-7"}}),(!_vm.isMobile && !_vm.isTablet)?_c('powered-by',{attrs:{"items":_vm.tokensTablePoweredByList}}):_vm._e()],1),_c('tokens-price-scrollable-table',{staticClass:"mt-2",attrs:{"items":_vm.tokensTableItemsCollection.items}}),(_vm.isMobile || _vm.isTablet)?_c('powered-by',{staticClass:"mt-3",attrs:{"display-inline":true,"items":_vm.tokensTablePoweredByList}}):_vm._e()],1):_vm._e(),(_vm.existsNfts && !_vm.shouldDisplayTokensTable)?_c('div',{staticClass:"dapp-detailed-tokens-nfts-section__table-wrapper",class:{
      'dapp-detailed-tokens-nfts-section__table-wrapper--only-one': !_vm.existsTokens,
    }},[_c('div',{staticClass:"justify-between horiz"},[_c('last-price-updated',{staticClass:"dapp-detailed-tokens-nfts-section__last-time-updated",attrs:{"display-inline":_vm.isMobile || _vm.isTablet,"last-time-updated":_vm.nftCollectionCollection.lastTimeUpdated,"icon-class":"w-4 h-4 md:w-7 md:h-7"}}),(!_vm.isMobile && !_vm.isTablet)?_c('powered-by',{attrs:{"items":_vm.nftsPoweredByList}}):_vm._e()],1),_c('nft-collection-table',{staticClass:"mt-2",attrs:{"collection":_vm.nftCollectionCollection,"is-dapp-detailed-page":true,"per-page":_vm.itemsPerPage}}),(_vm.isMobile || _vm.isTablet)?_c('powered-by',{staticClass:"mt-3",attrs:{"display-inline":true,"items":_vm.nftsPoweredByList}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }