


























































































import {Component, Prop, Vue} from 'vue-property-decorator'
import NavbarMenu from '@/components/NavbarMenu.vue'
import AppFooter from '@/components/AppFooter.vue'
import {NeoFeed} from '@/app/NeoFeed'
import DappDetailedStats from '@/components/dappDetailed/DappDetailedStats.vue'
import DappDetailedFeed from '@/components/dappDetailed/DappDetailedFeed.vue'
import DappDetailedSummary from '@/components/dappDetailed/DappDetailedSummary.vue'
import {Dapp} from '@/model/resource/Dapp'
import {DappInfo} from '@/model/resource/DappInfo'
import {ContractCollection} from '@/model/collection/ContractCollection'
import DappDetailedTokensNftsSection from '@/components/dappDetailed/DappDetailedTokensNftsSection.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'

@Component({
  components: {
    DappDetailedTokensNftsSection,
    DappDetailedStats,
    DappDetailedFeed,
    DappDetailedSummary,
    NavbarMenu,
    AppFooter,
  },
})
export default class DappDetailed extends Vue {
  @Prop({type: Boolean, required: true}) isDapp!: boolean

  tokensTableItemsCollection = new TokensTableItemsCollection()
  nftCollectionCollection = new NftCollectionCollection()
  contractCollection = new ContractCollection()

  dapp = new Dapp()
  dappInfo = new DappInfo()
  feed = new NeoFeed()

  initialHash = this.$route.params.hash ?? null

  async created() {
    await this.populateResources()
  }

  applyResources() {
    this.tokensTableItemsCollection.perPage = this.tokensTableItemsCollection.total
    this.tokensTableItemsCollection.orderBy = 'marketCap'
    this.tokensTableItemsCollection.minOrder = 0
    this.tokensTableItemsCollection.asc = false
    this.tokensTableItemsCollection.slugDapp = this.dapp.slug

    this.nftCollectionCollection.slugDapp = this.dapp.slug
    this.nftCollectionCollection.isVisible = true
    this.nftCollectionCollection.minOrder = 0

    if (this.dapp.blockchainVersion?.networkIdentifier) {
      this.nftCollectionCollection.networkIdentifier = this.dapp.blockchainVersion.networkIdentifier
      this.tokensTableItemsCollection.networkIdentifier = this.dapp.blockchainVersion.networkIdentifier
    }
  }

  async populateResources() {
    if (!this.$route.params.id) {
      await this.$router.push('/')
      return
    }

    try {
      this.$await.init('softQuery')

      const promises: Promise<unknown>[] = [
        this.dapp.getDapp(this.$route.params.id),
        this.dappInfo.getInfoDapp(this.$route.params.id),
      ]

      await Promise.all(promises)

      this.applyResources()

      const newPromises: Promise<unknown>[] = [
        this.tokensTableItemsCollection.queryAsPage(),
        this.nftCollectionCollection.queryAsPage(),
      ]

      await Promise.allSettled(newPromises)

      this.$await.done('softQuery')
    } catch {
      await this.$router.push('/')
    }

    try {
      this.feed.populate()
    } catch {
      this.feed = new NeoFeed()
    }
  }
}
